import React, { useState } from "react";
import ReactSelect from "react-select";
import scrollToElement from "scroll-to-element";

import Decide from "./Decide";
import Guide from "./Guide";
import Overview from "./Overview";
import PostStep from "./PostStep";
import Calendly from "./Calendly";
import ScanType from "./ScanTypes";
import Sidebar from "./Sidebar";
import UseCase from "./UseCase";

const sections = [
  { name: "Overview", value: "overview" },
  { name: "Types of scans", value: "scan-types" },
  { name: "How to decide", value: "decide" },
  { name: "Typical use cases", value: "use-case" },
  { name: "Post-referral steps", value: "post" },
];

function AhpGuide() {
  const [selected, setSelected] = useState("overview");
  return (
    <div className="-mb-12 bg-white pb-16 md:mt-0">
      <Guide />
      <div className="sticky top-0 mb-6 block border-b bg-white py-4 backdrop-blur supports-[backdrop-filter]:bg-white/80 md:hidden">
        <div className="container">
          <ReactSelect
            styles={{
              control: (base) => ({
                ...base,
                "&:focus": { border: "1px solid black" },
                "&:focus-within": { border: "2px solid #319497" },
                boxShadow: "green",
                borderColor: "#CBD5E1",
                borderRadius: "0.5rem",
                fontSize: "0.875rem",
                minHeight: 44,
                svg: { height: 17 },
              }),
              dropdownIndicator: (base) => ({
                ...base,
                color: "#666",
                position: "absolute",
                top: 4,
                right: 0,
              }),
              indicatorContainer: (base) => ({
                ...base,
                svg: { height: 10 },
              }),
              indicatorSeparator: (base) => ({ ...base, display: "none" }),
              input: (base) => ({
                ...base,
                "input:focus": { boxShadow: "none" },
              }),
            }}
            options={sections}
            isSearchable={false}
            value={sections.find((item) => item.value === selected)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.value}
            onChange={(e) => {
              setSelected(e.value);
              scrollToElement(`#${e.value}`, { offset: 0, duration: 1500 });
            }}
          />
        </div>
      </div>
      <div className="mx-auto block h-full max-w-5xl flex-col px-6 md:flex md:flex-row md:pt-10">
        <div className="-mt-8 hidden md:block">
          <Sidebar selected={selected} setSelected={setSelected} />
        </div>
        <div className="pl-0 md:pl-14">
          <Overview />
          <ScanType />
          <Decide />
          <UseCase />
          <PostStep />
          <Calendly />

          <div className="mt-6 mb-8 w-full border border-b bg-gray-200" />

          <p className="mb-4 font-medium text-gray-900">Sources</p>
          <div className="text-xs text-gray-500">
            <p>
              <a href="https://mediphysio.com.au/do-i-need-a-scan-to-scan-or-not-to-scan/">
                https://mediphysio.com.au/do-i-need-a-scan-to-scan-or-not-to-scan/
              </a>
            </p>
            <p>
              <a href="https://thephysios.com/do-i-need-a-scan-2/">
                https://thephysios.com/do-i-need-a-scan-2/
              </a>
            </p>
            <p>
              <a href="https://malvernphysio.com.au/do-you-need-a-scan-for-your-injury/">
                https://malvernphysio.com.au/do-you-need-a-scan-for-your-injury/
              </a>
            </p>
            <p>
              <a href="https://www.kenningtonosteopaths.co.uk/mri-ultrasound-or-xrays/">
                https://www.kenningtonosteopaths.co.uk/mri-ultrasound-or-xrays/
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AhpGuide;
