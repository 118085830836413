import { Button, ButtonLink } from "@scandotcom/react";
import {
  getScanImages,
  getScanInvoice,
  getScanReports,
  requestScanImages,
} from "@services/scan";
import { Referral, ScanDocument } from "@services/scan/types/common";
import { IconCheck, IconClock, IconFileText, IconPhoto } from "@tabler/icons";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { formatDate } from "utils/dates";
import { Panel } from "../Panel";

interface DocumentProps {
  name: string;
  description?: string;
  fileName?: string;
  uploadedAt?: string;
  onClick?: () => void;
  ctaText?: string;
  isImage?: boolean;
  href?: string;
}
const Document = ({
  name,
  description,
  fileName,
  uploadedAt,
  href,
  onClick,
  ctaText = "Download",
  isImage,
}: DocumentProps) => {
  const CardIcon = isImage ? IconPhoto : IconFileText;

  const wrapperStyles =
    "flex flex-wrap items-center gap-x-4 rounded-xl bg-cream-100 p-4 font-maison text-sm font-normal text-cream-800 w-full";

  return (
    <div className={wrapperStyles}>
      <CardIcon className="m-3 h-10 w-10" strokeWidth={1.5} />

      <div className="flex flex-col sm:max-w-[80%] sm:justify-center">
        <span className="text-base font-semibold text-cypress-800">{name}</span>

        {description && <p>{description}</p>}

        {fileName && (
          <div className="flex flex-col flex-wrap gap-x-1 sm:flex-row">
            <div className="font-bold text-cypress-500">{fileName}</div>
            {uploadedAt && (
              <span className="hidden font-bold text-cream-500 sm:block">
                {" "}
                &middot;{" "}
              </span>
            )}
            {uploadedAt && <div>Uploaded on {uploadedAt}</div>}
          </div>
        )}
      </div>

      {onClick && !href && (
        <Button
          className="mt-2 h-fit w-full sm:ml-auto sm:mt-0 sm:w-fit"
          onClick={onClick}
          size="sm"
        >
          {ctaText}
        </Button>
      )}

      {href && !onClick && (
        <ButtonLink
          target="_blank"
          rel="noopener"
          href={href}
          className="mt-2 h-fit w-full sm:ml-auto sm:mt-0 sm:w-fit"
          size="sm"
        >
          {ctaText}
        </ButtonLink>
      )}
    </div>
  );
};

interface DocumentsProps {
  className?: string;
  referral: Referral;
  additionalDocuments?: ScanDocument[];
}

export const Documents = ({
  referral,
  additionalDocuments = [],
  className,
}: DocumentsProps) => {
  const queryClient = useQueryClient();
  const queryProps = {
    staleTime: 1000 * 60 * 5,
    refetchInterval: 1000 * 60 * 4.5,
  };

  const requestImages = useMutation(requestScanImages, {
    onSuccess: (data) => {
      if (!data?.success || !data?.referral) return;

      queryClient.setQueryData(["referral", referral.id], data.referral);
    },
  });

  const images = useQuery(
    ["scan images", { referralId: referral.id }],
    () => getScanImages(referral.id),
    { ...queryProps, enabled: Boolean(referral.approvedScanImagesCount) }
  );

  const reports = useQuery(
    ["scan reports", { referralId: referral.id }],
    () => getScanReports(referral.id),
    { ...queryProps }
  );

  const invoice = useQuery(
    ["scan invoice", { referralId: referral.id }],
    () => getScanInvoice(referral.id),
    { ...queryProps }
  );

  const imageCount = images.data?.length || 0;
  const hasImages = Boolean(imageCount);

  const reportCount = reports.data?.length || 0;
  const hasReports = Boolean(reportCount);

  const additionalDocsCount = additionalDocuments?.length || 0;
  const hasAdditionalDocs = Boolean(additionalDocsCount);

  const hasInvoice = Boolean(invoice.data);

  const referralFormFilename = referral?.referralForm?.filename ?? "";
  const hasReferralForm = Boolean(referralFormFilename);
  const scanComplete = Boolean(referral?.completedAt);
  const scanImagesRequested = Boolean(referral.scanImagesRequestedAt);
  const canRequestImages =
    scanComplete && !scanImagesRequested && !requestImages.isLoading;

  const hasPendingDocs = !(hasReports && hasImages && hasReferralForm);

  const documentsCount =
    additionalDocsCount +
    Number(hasReferralForm) +
    reportCount +
    imageCount +
    Number(hasInvoice);

  const panelTitle = (
    <>
      Documents{" "}
      <span className="text-base text-neutral-400">({documentsCount})</span>
    </>
  );

  const titleStyles =
    "text-cypress-800 font-bold font-maison-extended tracking-wider text-xs flex flex-row gap-2 uppercase items-center mb-2";

  return (
    <Panel title={panelTitle} className={className}>
      {documentsCount !== 0 && (
        <>
          <div className={titleStyles}>
            <IconCheck className="w-5" strokeWidth={1.5} />
            Ready
          </div>
          <div data-test="documents-ready" className="mb-6 space-y-3">
            {hasReferralForm && (
              <Document
                name="Referral form"
                fileName={referralFormFilename}
                uploadedAt={formatDate(
                  referral.completedAt,
                  "dd MMM yyyy 'at' p a"
                )}
                href={referral.referralForm?.url}
              />
            )}

            {hasAdditionalDocs &&
              additionalDocuments.map(({ id, name, url }) => (
                <Document name={name} key={id} href={url} />
              ))}

            {hasReports &&
              reports.data?.map(({ id, filename, number, url }) => (
                <Document
                  name={`Report ${number}`}
                  fileName={filename}
                  key={id}
                  href={url}
                />
              ))}

            {hasImages &&
              images.data?.map(({ id, description, url }) => (
                <Document isImage key={id} name={description} href={url} />
              ))}

            {hasInvoice && (
              <Document
                name="Invoice"
                fileName={invoice.data?.filename}
                href={invoice.data?.url}
              />
            )}
          </div>
        </>
      )}

      {hasPendingDocs && (
        <div className={titleStyles}>
          <IconClock className="w-5" strokeWidth={1.5} />
          Pending
        </div>
      )}
      <div data-test="documents-pending" className="space-y-3">
        {!hasReferralForm && (
          <Document
            name="Referral form"
            description="Your referral form will be ready when the referral is processed."
          />
        )}

        {!hasReports && (
          <Document
            name="Scan report(s)"
            description="Your report will be ready 1-3 days after the scan. You will receive a notification and it will be available for download."
          />
        )}

        {!hasImages && (
          <Document
            isImage
            ctaText="Request Scan Images"
            name="Scan image(s)"
            onClick={
              canRequestImages
                ? () => requestImages.mutate(referral.id)
                : undefined
            }
            description={
              scanImagesRequested
                ? "As soon as images are available, you will be able to view them here."
                : "Scan images will be available once the scan has been completed."
            }
          />
        )}
      </div>
    </Panel>
  );
};

export default Documents;
