/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from "react";
import PropTypes from "prop-types";
import scrollToElement from "scroll-to-element";
import Scrollspy from "react-scrollspy";
import classNames from "classnames";

const linkStyles = classNames(
  "flex h-11 w-52 cursor-pointer items-center px-4 text-sm rounded-lg"
);

function Sidebar({ setSelected }) {
  const clickHandler = (id) => {
    scrollToElement(`#${id}`, { offset: 0, duration: 100 });
    setSelected(id);
  };

  return (
    <ul className="sticky top-1.5 w-1/4 pt-8 text-cream-800">
      <Scrollspy
        items={["overview", "scan-types", "decide", "use-case", "post", "demo"]}
        currentClassName="bg-cream-100 font-semibold text-cypress-900"
      >
        <li onClick={() => clickHandler("overview")} className={linkStyles}>
          Overview
        </li>
        <li onClick={() => clickHandler("scan-types")} className={linkStyles}>
          Types of scans
        </li>
        <li onClick={() => clickHandler("decide")} className={linkStyles}>
          How to decide
        </li>
        <li onClick={() => clickHandler("use-case")} className={linkStyles}>
          Typical use cases
        </li>
        <li onClick={() => clickHandler("post")} className={linkStyles}>
          Post-referral steps
        </li>
        <li onClick={() => clickHandler("demo")} className={linkStyles}>
          More Information
        </li>
      </Scrollspy>
    </ul>
  );
}

export default Sidebar;

Sidebar.propTypes = {
  setSelected: PropTypes.func.isRequired,
};
